import React from 'react'

function Footer() {
  return (
    <footer>
      <div className='d-flex justify-content-center text-muted'>
        <span>&copy; 2021 jackfperryjr@moogleapi</span>
      </div>
  </footer>
  )
}

export default Footer
